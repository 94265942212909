<script>
import parsePhoneNumber from 'libphonenumber-js'

import { mapGetters } from 'vuex'

import CustomCard from '../../components/CustomCard'
import { GET_TEMPLATE, ISSUE_USER, UPLOAD_FILE } from '../../store/actions'

import TemplateCanvas from '../../components/TemplateEditor/components/Canvas/Canvas'

import MessageModal from '../../components/TemplateEditor/modals/MessageModal'
import { DrivingLicenseCategories } from '../../consts/designPresets'

const KEYVAL = 'keyval'
const TABLE = 'table'
const LOGOS = 'logos'
const SIGS = 'sigs'

export default {
  name: 'IssueCredential',
  title: 'pageTitle.issueCredential',
  components: {
    CustomCard,
    MessageModal,
    TemplateCanvas,
  },
  emits: ['close'],
  data() {
    return {
      // Data to delete
      customTemplate: { 'wallid.io': true },

      currentColor: null,

      editable: true,
      backTemplate: null,
      frontTemplate: [],

      tableValues: [],
      tableLine: [],
      tables: [],
      logos: [],
      sigs: [],

      // Currently used
      tid: '',
      DrivingLicenseCategories,
      menu: {
        DATEOFBIRTH: false,
        EXPIRYDATE: false,
      },
      grantedMenu: false,
      validFromMenu: false,
      validUntilMenu: false,
      category: {},
      granted: '',
      validFrom: '',
      validUntil: '',
      categoriesList: [],

      step: 0,
      isLoaded: false,
      imageLoading: false,

      email: null,
      errorMail: null,

      reload: true,
      sending: false,

      components: [],
      templateValuesFront: [],
      templateValuesBack: [],
      templateCopy: null,
      photoURLBase64: null,

      buttonText: this.$t('button.next'),
      showSuccessModal: false,
      pages: ['#page'],

      currentFonts: new Set(),

      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || this.$t('issue.emailError'),
      ],
    }
  },
  computed: {
    ...mapGetters(['adminEmail', 'cid', 'renderedDimensions']),
    ...mapGetters('template', [
      'frontendProps',
      'caName',
      'credName',
      'urlPhoto',
      'cardHeaders',
      'templateItens',
      'customTemplateName',
      'template',
    ]),
    canvasStyle() {
      return 'max-height: 60vh;'
    },
    addCategoryDisabled() {
      return !this.category.title || !this.granted || !this.validFrom || !this.validUntil
    },
  },
  watch: {
    template() {
      this.getTemplate()
    },
  },

  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    console.log('beforeUnmount() tid: ', this.tid)
    window.onbeforeunload = null
  },
  mounted() {
    window.onbeforeunload = function () {
      return 'Are you sure you want to close the window?'
    }
    this.$log.debug('mounted() tid: ', this.tid, this.cid)
    this.tid = this.$route.params.tid
    if (this.tid !== '' && this.cid) {
      this.initialLoad()
    }
  },
  methods: {

    addCategory() {
      console.log('addCategory', this.category, this.granted, this.validFrom, this.validUntil)
      const category = {
        ...this.category,
        granted: this.granted,
        validFrom: this.validFrom,
        validUntil: this.validUntil,
      }
      this.categoriesList.push(category)
      this.category = {}
      this.granted = ''
      this.validFrom = ''
      this.validUntil = ''
    },
    changeBackground(e, index) {
      this.frontendProps.backgroundFront = e
      this.templateValuesFront[index].value = e
    },

    uploadPhoto(file, folder) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPLOAD_FILE, { file, folder })
          .then((url) => {
            resolve(url)
          })
          .catch((err) => {
            this.logError('uploadPhotos', err)
            reject(err)
          })
      })
    },
    uploadImage(field, index) {
      this.debug('uploadImage function', field, index)
      this.$log.debug(this.$refs)
      this.$refs.uploadImage[index].click()
    },
    async newImage(event, field) {
      this.imageLoading = true
      field.value = await this.$store
        .dispatch(UPLOAD_FILE, {
          file: event.target.files[0],
          folder: `${this.tid}`,
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
      this.imageLoading = false

      this.replaceAttributeImage(field)
    },
    async initialLoad() {
      this.debug('Call GET_TEMPLATE action')
      // const template = await this.$store.dispatch(GET_TEMPLATE, {
      //   tid: this.$route.params.tid,
      // })
      const template = this.template
      if (template) {
        this.templateName = template.name
      }
      this.debug('IssueCertificate: ', this.customTemplateName)
      if (this.customTemplateName === 'templateEditor') {
        this.debug('frontendProps', this.frontendProps)

        this.createTemplateItems()

        this.$store.dispatch('enableBackSide', false)

        if (this.frontendProps.backgroundBack) {
          if (this.pages.length === 1) { this.pages.push('#back') }

          this.$store.dispatch('enableBackSide', true)
        }

        // copy template values to reset when beforeDestroy() is called
        this.templateCopy = JSON.parse(JSON.stringify(this.frontendProps))

        this.components = this.frontendProps.components

        await this.splitBaseTextIntoArray()

        this.$store.dispatch('setTemplate', this.frontendProps)
        this.$store.commit('setActiveComponent', '')
        this.isLoaded = true
        this.$forceUpdate()
      }
      else {
        this.createOldTemplate()
        this.replaceImagesURL()
      }
    },
    async splitBaseTextIntoArray() {
      for (const i in this.components) {
        if (this.components[i].templateSide === 'back') {
          if (this.pages.length === 1) { this.pages.push('#back') }

          this.$store.dispatch('enableBackSide', true)
        }
        if (
          this.components[i].type === 'text'
          || (this.components[i].type === 'image'
          && this.components[i].dynamicImage)
        ) {
          // this.$log.debug(this.components[i]);
          // .filter(function(el) {
          //   return el;
          // });
          //  /((?:\[[A-Z0-9 ]+\]))|([a-zA-Z0-9]+)/g
          if (this.components[i].fontFamily) { this.currentFonts.add(this.components[i].fontFamily) }

          this.components[i].baseText = this.components[i].text.match(

            /(\[[A-Z0-9 ]+\])|([a-zA-Z0-9!@#$&()\\-`.+,/\"]*)/g,
          )
          this.components[i].originalText = this.components[i].text.match(

            /(\[[A-Z0-9 ]+\])|([a-zA-Z0-9!@#$&()\\-`.+,/\"]*)/g,
          )
        }
        if (this.components[i].type === 'image') {
          console.log('components[i]', this.components[i])
          this.components[i].src = await this.toDataURL(
            this.components[i].src,
          )
        }
      }

      this.templateValuesFront.forEach((item) => {
        // this.$log.debug(item);

        item.indexArray = this.findAttr(item)
      })
      this.templateValuesBack.forEach((item) => {
        // this.$log.debug(item);

        item.indexArray = this.findAttr(item)
      })
      this.templateValuesFront.sort(this.compareAttr)
      this.templateValuesBack.sort(this.compareAttr)

      this.$log.debug('templateValuesFront', this.templateValuesFront)
      this.$log.debug('templateValuesBack', this.templateValuesBack)
    },

    compareAttr(a, b) {
      if (a.attr < b.attr) { return -1 }

      if (a.attr > b.attr) { return 1 }

      return 0
    },
    findAttr(item) {
      let indexAttr, indexComponent

      for (const i in this.components) {
        if (
          (this.components[i].type === 'text'
          && this.components[i].baseText
          && this.components[i].baseText.length > 0)
          || (this.components[i].type === 'image'
          && this.components[i].dynamicImage)
        ) {
          indexAttr = this.components[i].baseText.findIndex((el) => {
            return el.includes(`[${item.attr}]`)
          })
          // this.$log.debug(indexAttr);
          if (indexAttr > -1) {
            indexComponent = i
            break
          }
        }
      }
      return { indexComponent, indexAttr }
    },
    replaceAttributeImage(component) {
      this.$log.debug('START REPLACE FUNCTION')
      this.$log.debug(component)
      const indexComponent = component.indexArray.indexComponent

      this.components[indexComponent].src = component.value
      this.$forceUpdate()
    },
    replaceAttributeText(component) {
      this.$log.debug('START REPLACE FUNCTION')
      this.$log.debug(component)
      if (this.customTemplateName === 'templateEditor') {
        const indexAttr = component.indexArray.indexAttr
        const indexComponent = component.indexArray.indexComponent

        this.$log.debug(this.components[indexComponent])
        // Get Base text
        const baseText = this.components[indexComponent].baseText

        if (component.value) {
          baseText[indexAttr] = component.value
          this.$log.debug(baseText)
        }
        else {
          baseText[indexAttr]
            = this.components[indexComponent].originalText[indexAttr]
        }
        if (this.components[indexComponent].maxCharacters) {
          component.maxCharacters
            = this.components[indexComponent].maxCharacters
        }

        const newValue = baseText.join(' ')
        // this.$log.debug(newValue);
        this.components[indexComponent].text = newValue
        this.$forceUpdate()
      }
    },

    createTemplateItems() {
      // this.$log.debug(this.templateItens);
      this.templateItens.forEach((e) => {
        if (e.order === 0) {
          this.templateValuesFront.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
          })
        }
        else if (e.order === 1) {
          this.templateValuesBack.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
          })
        }
      })
    },

    createOldTemplate() {
      this.currentColor = this.frontendProps
        ? this.frontendProps.color
        : null
      this.templateItens.forEach((e) => {
        if (e.attrFormat === KEYVAL) {
          this.frontTemplate.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
            order: e.order,
          })
          this.templateValuesFront.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
          })
        }
        else if (e.attrFormat === TABLE) {
          this.tables.push({
            headers: e.table_headers,
            values: e.table_attr,
            item_id: e._id,
          })
          this.backTemplate = {
            headers: e.table_headers,
            values: e.table_attr,
          }
        }
        else if (e.attrFormat === LOGOS) {
          this.$log.debug(e)
          this.logos = e.logos
        }
        else if (e.attrFormat === SIGS) {
          this.$log.debug(e)
          this.sigs = e.sigs
        }
      })

      this.frontTemplate.sort(this.compare)

      this.tables.forEach((t, i) => {
        this.tableLine.push([])
        this.$log.debug(t)

        t.headers.forEach((e) => {
          this.$log.debug(e)

          const el = {
            attr: e.text,
            input: e.value,
            value: null,
            error: false,
          }
          el[e.value] = null
          this.$log.debug(el)

          this.tableLine[i].push(el)
        })
      })
      this.debug(this.templateValues)
      this.debug(this.frontTemplate)
      this.debug(this.tableLine)
    },

    async toDataURL(url) {
      console.log('toDataURL', url)
      if (url?.startsWith('data:image')) { return url }

      return await new Promise((resolve) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function () {
          const reader = new FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.onerror = function () {
          resolve(undefined)
          console.error('** An error occurred during the XMLHttpRequest')
        }
        xhr.open('GET', `${url}?_=${Date.now()}`, true)
        xhr.responseType = 'blob'
        xhr.send()
      })
      // var xhr = new XMLHttpRequest();
      // xhr.onload = function () {
      //   var reader = new FileReader();
      //   reader.onloadend = function () {
      //     callback(reader.result);
      //   };
      //   reader.readAsDataURL(xhr.response);
      // };
      // xhr.open('GET', url + '?_=' + Date.now(), true);
      // xhr.responseType = 'blob';
      // xhr.send();
    },
    async replaceImagesURL() {
      // testind if this is required or not
      // this.$nextTick(() => {
      this.photoURLBase64 = await this.toDataURL(this.urlPhoto)

      this.frontendProps.background_url = await this.toDataURL(
        this.frontendProps.background_url,
      )
      window.scroll(0, 0)
      // Wait for Vue update
      // this.$nextTick(() => this.print(next));
      // });
    },
    compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const valA = a.order
      const valB = b.order

      let comparison = 0
      if (valA > valB) { comparison = 1 }
      else if (valA < valB) { comparison = -1 }

      return comparison
    },
    isNFT() {
      // console.log(this.adminEmail);
      return (
        this.frontendProps
        && this.frontendProps.currentLayout
        && this.frontendProps.currentLayout === 'NFT'
      )
    },
    removeItem(index) {
      this.tableValues.splice(index, 1)
    },
    addTableLine(indexT) {
      this.$log.debug(this.tableLine[indexT])
      const el = {}
      let error = false
      this.tableLine[indexT].forEach((e, index) => {
        if (e.value) {
          this.tableLine[indexT][index].error = false
          el[e.input] = e.value
          e.value = null
        }
        else {
          this.tableLine[indexT][index].error = true
          error = true
        }
      })
      if (!error) { this.tableValues.push(el) }
    },
    clearErrorTable(indexT, indexL) {
      this.tableLine[indexT][indexL].error = false
    },
    clearError(index) {
      if (
        this.templateValuesFront[index]
        && this.templateValuesFront[index].value
      ) { this.templateValuesFront[index].error = false }
      else if (
        this.templateValuesBack[index]
        && this.templateValuesBack[index].value
      ) { this.templateValuesBack[index].error = false }
      else { this.templateValuesFront[index].error = true }
    },
    checkForm() {
      this.debug('Check form')
      let error = false
      this.templateValuesFront.forEach((e) => {
        if ((e.isMandatory === 'true' || e.isMandatory === true) && !e.value) {
          e.error = true
          error = true
        }
        else if (['DATEOFBIRTH', 'EXPIRYDATE'].includes(e.attr)) {
          const date = new Date(e.value)
          if (date.toString() === 'Invalid Date') {
            e.error = true
            error = true
          }
          else {
            e.error = false
          }
        }
        else if (['PHONE', 'PHONENUMBER'].includes(e.attr)) {
          const phone = parsePhoneNumber(e.value)
          if (!phone || !phone.isValid()) {
            e.error = true
            error = true
          }
          else {
            e.error = false
          }
        }
      })
      this.templateValuesBack.forEach((e) => {
        if ((e.isMandatory === 'true' || e.isMandatory === true) && !e.value) {
          e.error = true
          error = true
        }
        else {
          e.error = false
        }
      })
      if (this.tables && this.tables.length > 0 && this.tableValues.length === 0) { error = true }

      return error
    },
    async createData() {
      let templateValuesFrontMapped = this.templateValuesFront.map((e) => {
        return {
          value: e.value,
          temp_item_id: e.temp_item_id,
          isPublic: e.isPublic,
        }
        // }
      })

      await Promise.all(templateValuesFrontMapped).then((results) => {
        templateValuesFrontMapped = results
      })
      let templateValuesBackMapped = this.templateValuesBack.map((e) => {
        return {
          value: e.value,
          temp_item_id: e.temp_item_id,
          isPublic: e.isPublic,
        }
        // }
      })

      await Promise.all(templateValuesBackMapped).then((results) => {
        templateValuesBackMapped = results
      })
      this.data = templateValuesFrontMapped.concat(templateValuesBackMapped)
      if (
        this.tables
        && this.tables.length > 0
        && this.tableValues.length > 0
      ) {
        this.data.push({
          values: this.tableValues,
          temp_item_id: this.tables[0].item_id,
          isPublic: true,
        })
      }
      this.$log.debug('Generated Data: ', this.data)
    },
    close() {
      this.$emit('close')
    },
    back() {
      this.step = 0
      this.buttonText = this.$t('button.next')
      this.email = null
      this.editable = true
    },
    checkCategories() {
      if (this.categoriesList.length === 0) {
        return true
      }
      return false
    },
    async nextStep() {
      try {
        switch (this.step) {
          case 0:
            if (!this.checkForm()) {
              await this.createData()
              this.step += 1
              this.editable = false
              this.sending = false
            }
            else {
              this.sending = false
            }
            break
          case 1:
            if (!this.checkCategories()) {
              this.step += 1
              this.buttonText = this.$t('button.issue')
              this.editable = false
              this.sending = false
            }
            else {
              this.sending = false
            }
            break
          case 2:
            this.debug('Emit cert')
            this.sending = true
            this.errorMail = null
            if (this.email) {
              await this.$nextTick()

              await this.$store
                .dispatch(ISSUE_USER, {
                  tid: this.tid,
                  email: this.email,
                  data: this.data,
                  categories: this.categoriesList,
                  // imgArray,
                })
                .then(() => {
                  this.showSuccessModal = true
                })
                .catch((err) => {
                  console.error(err)
                  this.sending = false
                })
            }
            else {
              this.errorMail = this.$t('issue.emailError')
            }
            break

          default:
            break
        }
      }
      catch (error) {
        console.error(error)
        // } finally {
        //   this.sending = false;
      }
    },
  },

}
</script>

<template>
  <v-container class="issue-cred px-0">
    <v-row>
      <v-col cols="6" class="">
        <h2 class="title_header pr-4">
          {{ $t('issue.title') }}
        </h2>
        <div class="steps">
          {{ $t('step[0]') }} {{ step + 1 }} {{ $t('step[1]') }} 3
        </div>
      </v-col>
      <v-col cols="6" class="text-right pr-0">
        <v-btn v-if="step === 1" class="back mr-4" @click="back()">
          {{
            $t('button.back')
          }}
        </v-btn>
        <v-btn class="next" :loading="sending" @click="nextStep()">
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card flat class="pa-0 mt-6 issue-form-wrapper">
      <v-row>
        <v-col cols="5" class="pt-0" style="max-height: 55vh; overflow-y: auto">
          <v-stepper v-model="step" elevation="0">
            <v-stepper-items>
              <v-stepper-content step="0" class="pa-3">
                <v-container class="">
                  <v-row>
                    <v-col
                      v-if="templateValuesBack.length > 0"
                      cols="12"
                      class=""
                    >
                      <p class="mb-0 side-header">
                        {{ $t('createCertModal.front') }}
                      </p>
                    </v-col>
                    <v-col
                      v-for="(field, index) in templateValuesFront"
                      :key="field._id"
                      cols="12"
                      class="input-field pb-0"
                    >
                      <label class="">
                        {{
                          field.attr === 'IMAGE' && field.type === 'image'
                            ? $t('issue.labelUpload')
                            : field.attr
                        }}
                        <span
                          v-if="field.isMandatory === 'true'"
                          style="color: #e95e5e"
                        >
                          *
                        </span>
                      </label>

                      <v-menu
                        v-if="['DATEOFBIRTH', 'EXPIRYDATE'].includes(field.attr)"
                        v-model="menu[field.attr]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            color="#009fb1"
                            readonly
                            :value="templateValuesFront[index].value"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="templateValuesFront[index].value"
                          :locale="$i18n.locale"
                          no-title
                          @input="
                            menu[field.attr] = false;
                            replaceAttributeText(field);
                          "
                        />
                      </v-menu>

                      <!-- text inputs -->
                      <v-text-field
                        v-else-if="field.type !== 'image'"
                        v-model="templateValuesFront[index].value"
                        class="mt-2"
                        :type="field.type"
                        flat
                        solo
                        :maxlength="field.maxCharacters"
                        :counter="field.maxCharacters"
                        :error="templateValuesFront[index].error"
                        :label="['PHONENUMBER'].includes(field.attr) ? 'p. ej. +598 99 123 456' : ''"
                        @input="
                          clearError(index);
                          replaceAttributeText(field);
                        "
                      />
                      <v-btn
                        v-show="field.type === 'image'"
                        text
                        :loading="imageLoading"
                        class="advance-btn upload my-5"
                        @click="uploadImage(field, field.indexArray.indexAttr)"
                      >
                        {{ $t('issue.buttonUpload') }}
                      </v-btn>

                      <input
                        v-if="field.type === 'image'"
                        ref="uploadImage"
                        type="file"
                        style="display: none"
                        accept="image/*"
                        @change="newImage($event, field)"
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="templateValuesBack.length > 0"
                      cols="12"
                      class=""
                    >
                      <p class="mb-0 side-header">
                        {{ $t('createCertModal.back') }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="(field, index) in templateValuesBack"
                      :key="field._id"
                      cols="12"
                      class="input-field pb-0"
                    >
                      <label class="">{{ field.attr }}

                        <span
                          v-if="field.isMandatory === 'true'"
                          style="color: #e95e5e"
                        >
                          *
                        </span>
                      </label>
                      <v-text-field
                        v-model="templateValuesBack[index].value"
                        class="mt-2"
                        :type="field.type"
                        flat
                        solo
                        :error="templateValuesBack[index].error"
                        @input="
                          clearError(index);
                          replaceAttributeText(field);
                        "
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
              <v-stepper-content step="1" class="pa-3">
                <v-container class="">
                  <v-row>
                    <v-col
                      cols="12"
                      class="pb-0"
                      style="padding-right: 10px"
                    >
                      <label class=""> Añadir categoría</label>
                      <v-select
                        v-model="category"
                        color="#009fb1"
                        label="Categoría"
                        class="category-select mt-2"
                        flat
                        solo
                        :items="DrivingLicenseCategories"
                      >
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.gd }} - {{ item.title }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <template #selection="{ item }">
                          {{ item.gd }} - {{ item.title }}
                        </template>
                      </v-select>

                      <v-menu
                        v-model="grantedMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            label="Otorgado"
                            color="#009fb1"
                            readonly
                            :value="granted"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="granted"
                          :locale="$i18n.locale"
                          no-title
                          @input="grantedMenu = false"
                        />
                      </v-menu>

                      <v-menu
                        v-model="validFromMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            label="Válido desde"
                            color="#009fb1"
                            readonly
                            :value="validFrom"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="validFrom"
                          locale="en-in"
                          no-title
                          @input="validFromMenu = false"
                        />
                      </v-menu>
                      <v-menu
                        v-model="validUntilMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            label="Válido hasta"
                            color="#009fb1"
                            readonly
                            :value="validUntil"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="validUntil"
                          locale="en-in"
                          no-title
                          @input="validUntilMenu = false"
                        />
                      </v-menu>

                      <v-btn :disabled="addCategoryDisabled" class="next" style="border: solid 1px grey" @click="addCategory">
                        Agregar
                      </v-btn>

                      <v-col v-for="(c, index) in categoriesList" :key="c.id">
                        <v-row>
                          <v-col cols="10">
                            <div>
                              {{ c.gd }} - {{ c.title }}
                            </div>
                          </v-col>
                          <v-col cols="2">
                            <div
                              style="cursor: pointer;"
                              @click="categoriesList.splice(index, 1)"
                            >
                              <v-icon
                                color="red"
                              >
                                mdi-delete
                              </v-icon>
                            </div>
                          </v-col>

                          <v-col cols="4">
                            <div>
                              <label> Otorgado: </label>
                              {{ c.granted }}
                            </div>
                          </v-col>
                          <v-col cols="4">
                            <div>
                              <label> Válido desde: </label>
                              {{ c.validFrom }}
                            </div>
                          </v-col>
                          <v-col cols="4">
                            <div>
                              <label> Válido hasta: </label>
                              {{ c.validUntil }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-3">
                <v-container class="">
                  <v-row>
                    <v-col
                      cols="12"
                      class="input-field pb-0"
                      style="padding-right: 10px"
                    >
                      <label class=""> {{ $t('issue.emailField') }}</label>
                      <v-text-field
                        v-model="email"
                        class="mt-2"
                        flat
                        persistent-hint
                        :rules="emailRules"
                        :hint="$t('issue.emailHint')"
                        :error-messages="errorMail"
                        solo
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
        <v-col cols="7" class="pt-0 template-background">
          <v-row>
            <v-col
              v-show="customTemplateName === 'templateEditor'"
              cols="12"
              class="pt-6"
            >
              <TemplateCanvas
                v-if="reload || frontendProps.currentLayout === 'Card'"
                :editable="false"
                :width="step === 1 ? 376 : 480"
                :style="canvasStyle"
              />
            </v-col>
            <v-col
              v-if="customTemplateName !== 'templateEditor' && isLoaded"
              cols="12"
              class="static_image pt-0"
            >
              <CustomCard
                :front-template="frontTemplate"
                :back-template="backTemplate"
                :tableValues="tableValues"
                :template-values="templateValuesFront"
                :ca-name="caName"
                :credential-name="credName"
                :url-photo="photoURLBase64"
                :editable="editable"
                :frontend-props="frontendProps"
                @remove-item="removeItem"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <MessageModal
      v-if="showSuccessModal"
      message="email"
      @close="$router.go(-1)"
    />
  </v-container>
</template>

<style lang="scss">
.category-select{
  .v-input__slot{
    border: 1px solid #009fb1;
    border-radius: 3px;
  }
}

// Styles for canvas

.issue-form-wrapper {
  max-height: 70vh;
}
.page {
  textarea {
    overflow: hidden;
  }
}

.issue-cred {
  .template-background {
    background-color: #e2e2e2;
  }
  .passepartout {
    padding-top: 0.8rem;
  }
  .v-stepper {
    box-shadow: none;
  }
  .confirm.v-btn {
    background-color: #00808e !important ;
  }
  .modal-body.scroll {
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .static_image {
    height: 265px;
    max-width: 376px;
    background: white;
    position: relative;
    border-radius: 18px;

    margin: 50px auto;

    .id-card {
      position: absolute;
      margin-left: -12px;
      margin-right: -12px;
    }
  }
  .input-field {
    display: flex;
    flex-direction: column;
    .advance-btn.upload {
      max-width: 150px;
      svg {
        margin-top: 0 !important;
      }
    }
    .v-input--radio-group {
      .v-input__slot {
        border: none !important;
      }
    }
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
        .v-label{
          color: var(--light-grey-blue) !important;
        }
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
  div.steps {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--teal-blue);
  }
  h2.title_header,
  div.steps {
    display: inline;
  }
  .side-header {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    text-transform: uppercase;
  }
}
</style>
